import { createStore } from 'vuex'
import { auth } from './auth.module';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        isDark: true,
        companieId: 0,
        companieData: null,
        apiURL: process.env.VUE_APP_API_URL,
        showMenu: false,
        assistantId: 0,
        voice: null,
        toolsCategories: [
            {
                "slug": "serp-checker",
                "name": "Verificador de SERP",
                "icon": "manage-search",
                "category": "research",
                "description": "Obtenha os principais resultados de busca para uma palavra-chave."
            },
            {
                "slug": "indexed-pages-checker",
                "name": "Verificador de páginas indexadas",
                "icon": "find-in-page",
                "category": "research",
                "description": "Verifique se um domínio está indexado pelos motores de busca."
            },
            // {
            //     "slug": "keyword-research",
            //     "name": "Pesquisa de palavras-chave",
            //     "icon": "abc-icon",
            //     "category": "research",
            //     "description": "Pesquise o volume de busca, CPC e concorrência de palavras-chave."
            // },
            {
                "slug": "website-status-checker",
                "name": "Verificador de status do site",
                "icon": "wifi-tethering",
                "category": "research",
                "description": "Verifique o status e a disponibilidade de um site."
            },
            {
                "slug": "ssl-checker",
                "name": "Verificador de SSL",
                "icon": "lock-icon",
                "category": "research",
                "description": "Verifique o status e as informações do SSL de um domínio."
            },
            {
                "slug": "dns-lookup",
                "name": "Consulta de DNS",
                "icon": "dns-icon",
                "category": "research",
                "description": "Consulte a lista de registros DNS de um domínio."
            },
            {
                "slug": "whois-lookup",
                "name": "Consulta WHOIS",
                "icon": "info-icon",
                "category": "research",
                "description": "Consulte informações de propriedade de um domínio."
            },
            {
                "slug": "ip-lookup",
                "name": "Consulta de IP",
                "icon": "travel-explore",
                "category": "research",
                "description": "Obtenha informações de localização para um endereço IP."
            },
            {
                "slug": "reverse-ip-lookup",
                "name": "Consulta reversa de IP",
                "icon": "searched-for",
                "category": "research",
                "description": "Obtenha o nome do host correspondente a um endereço IP."
            },
            {
                "slug": "domain-ip-lookup",
                "name": "Consulta de IP de domínio",
                "icon": "share-location",
                "category": "research",
                "description": "Obtenha o endereço IP e informações de localização de um domínio."
            },
            {
                "slug": "redirect-checker",
                "name": "Verificador de redirecionamento",
                "icon": "shuffle-icon",
                "category": "development",
                "description": "Analise o caminho completo de redirecionamento de uma URL."
            },
            {
                "slug": "idn-converter",
                "name": "Conversor de IDN",
                "icon": "language-icon",
                "category": "research",
                "description": "Converta texto para Punycode e Punycode para texto."
            },
            {
                "slug": "js-minifier",
                "name": "Minificador de JS",
                "icon": "js-icon",
                "category": "development",
                "description": "Minifique e melhore o desempenho do código JS."
            },
            {
                "slug": "css-minifier",
                "name": "Minificador de CSS",
                "icon": "css-icon",
                "category": "development",
                "description": "Minifique e melhore o desempenho do código CSS."
            },
            {
                "slug": "html-minifier",
                "name": "Minificador de HTML",
                "icon": "html-icon",
                "category": "development",
                "description": "Minifique e melhore o desempenho do código HTML."
            },
            {
                "slug": "json-validator",
                "name": "Validador de JSON",
                "icon": "data-object",
                "category": "development",
                "description": "Valide uma string JSON e a transforme em um formato legível."
            },
            {
                "slug": "password-generator",
                "name": "Gerador de senhas",
                "icon": "password-icon",
                "category": "development",
                "description": "Gere uma senha aleatória com base em parâmetros personalizados."
            },
            {
                "slug": "qr-generator",
                "name": "Gerador de QR",
                "icon": "qr-icon",
                "category": "development",
                "description": "Gere um código QR personalizável para um texto."
            },
            {
                "slug": "user-agent-parser",
                "name": "Analisador de User-Agent",
                "icon": "tab-icon",
                "category": "development",
                "description": "Analise um User-Agent em componentes individuais legíveis."
            },
            {
                "slug": "md5-generator",
                "name": "Gerador de MD5",
                "icon": "tag-icon",
                "category": "development",
                "description": "Gere um valor de hash usando o algoritmo MD5."
            },
            {
                "slug": "color-converter",
                "name": "Conversor de cores",
                "icon": "style-icon",
                "category": "development",
                "description": "Converta entre os modelos de cores HEX, RGB e HLS."
            },
            {
                "slug": "utm-builder",
                "name": "Construtor de UTM",
                "icon": "label-icon",
                "category": "development",
                "description": "Adicione parâmetros de campanha UTM a uma URL."
            },
            {
                "slug": "url-parser",
                "name": "Analisador de URL",
                "icon": "dataset-linked",
                "category": "development",
                "description": "Analise uma URL em componentes individuais legíveis."
            },
            {
                "slug": "uuid-generator",
                "name": "Gerador de UUID",
                "icon": "view-stream",
                "category": "development",
                "description": "Gere um identificador único universal versão 4."
            },
            {
                "slug": "lorem-ipsum-generator",
                "name": "Gerador de Lorem Ipsum",
                "icon": "notes-icon",
                "category": "content",
                "description": "Gere texto de preenchimento para parágrafos, frases e palavras."
            },
            {
                "slug": "text-cleaner",
                "name": "Limpeza de texto",
                "icon": "format-clear",
                "category": "content",
                "description": "Limpe tags HTML, espaços e quebras de linha de um texto."
            },
            {
                "slug": "word-density-counter",
                "name": "Contador de densidade de palavras",
                "icon": "line-weight",
                "category": "content",
                "description": "Conte o número e a densidade de cada palavra em um texto."
            },
            {
                "slug": "word-counter",
                "name": "Contador de palavras",
                "icon": "pin-icon",
                "category": "content",
                "description": "Conte o número de palavras e letras em um texto."
            },
            {
                "slug": "case-converter",
                "name": "Conversor de caixa",
                "icon": "expand-icon",
                "category": "content",
                "description": "Transforme texto para minúsculas, maiúsculas, frase ou capitalizado."
            },
            {
                "slug": "text-to-slug-converter",
                "name": "Conversor de texto para slug",
                "icon": "link-icon",
                "category": "content",
                "description": "Converta texto em um slug de URL amigável para SEO."
            },
            {
                "slug": "url-converter",
                "name": "Conversor de URL",
                "icon": "url-icon",
                "category": "content",
                "description": "Codifique ou decodifique um texto para ser usado em URLs."
            },
            {
                "slug": "base64-converter",
                "name": "Conversor de Base64",
                "icon": "border-bottom",
                "category": "content",
                "description": "Codifique texto para Base64 ou decodifique Base64 para texto."
            },
            {
                "slug": "binary-converter",
                "name": "Conversor de binário",
                "icon": "money-icon",
                "category": "content",
                "description": "Converta números binários para texto e texto para números binários."
            },
            {
                "slug": "text-replacer",
                "name": "Substituidor de texto",
                "icon": "find-replace",
                "category": "content",
                "description": "Encontre e substitua todas as ocorrências de uma string em um texto."
            },
            {
                "slug": "text-reverser",
                "name": "Inversor de texto",
                "icon": "cached-icon",
                "category": "content",
                "description": "Inverta a ordem de um texto."
            }
        ],
        iaCategories: [
            {
                app: "assistants",
                title: 'Chat',
                description: "Converse com assistentes de IA",
                text: "Assistente IA",
                colors: ["#00A6FB", "#006ABF"],
                icon: '<svg class="drop-shadow-sm w-7/12" viewBox="0 0 24 24" xmlns=http://www.w3.org/2000/svg><g fill=none fill-rule=nonzero id=message_1_fill><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"></path><path d="M19 3a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3h-3.697l-2.61 1.74c-.42.28-.966.28-1.386 0L8.697 19H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h14ZM8.5 10a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm7 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"fill=currentColor></path></g></svg>'
            },
            {
                app: "coder",
                title: 'Coder',
                description: "Pronto para escrever código na velocidade da luz?",
                text: "Escritor de código",
                colors: ["#F099C3", "#E03339"],
                icon: '<svg class="drop-shadow-sm w-7/12" viewBox="0 0 24 24" xmlns=http://www.w3.org/2000/svg><g fill=none fill-rule=nonzero id=code_fill><path d="M0 0h24v24H0z"></path><path d="M14.62 2.662a1.5 1.5 0 0 1 1.04 1.85l-4.431 15.787a1.5 1.5 0 0 1-2.889-.81L12.771 3.7a1.5 1.5 0 0 1 1.85-1.039ZM7.56 6.697a1.5 1.5 0 0 1 0 2.12L4.38 12l3.182 3.182a1.5 1.5 0 1 1-2.122 2.121L1.197 13.06a1.5 1.5 0 0 1 0-2.12l4.242-4.243a1.5 1.5 0 0 1 2.122 0Zm8.88 2.12a1.5 1.5 0 1 1 2.12-2.12l4.243 4.242a1.5 1.5 0 0 1 0 2.121l-4.242 4.243a1.5 1.5 0 1 1-2.122-2.121L19.621 12 16.44 8.818Z"fill=currentColor></path></g></svg>'
            },
            {
                app: "imagine",
                title: 'Imagens IA',
                description: "Visualize o que você sonha.",
                text: "Gerador de imagem",
                colors: ["#E6C0FE", "#984CF8"],
                icon: '<svg class="drop-shadow-sm w-7/12" viewBox="0 0 24 24" xmlns=http://www.w3.org/2000/svg><g fill=none fill-rule=evenodd id=photo_album_2_fill><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"></path><path d="M5 3a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H6a1 1 0 0 1-1-1Zm0 2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5Zm14 8.686V7H5v12h.929l8.308-8.308a1.25 1.25 0 0 1 1.768 0L19 13.686ZM8.5 12a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"fill=currentColor></path></g></svg>'
            },
            {
                app: "transcriber",
                title: 'Áudio para Texto',
                description: "Transcreva instantaneamente palavras faladas em texto.",
                text: "Fala para texto",
                colors: ["#30C862", "#00A6FB"],
                icon: '<svg class="drop-shadow-sm w-7/12" viewBox="0 0 24 24" xmlns=http://www.w3.org/2000/svg><g fill=none fill-rule=nonzero id=headphone_fill><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"></path><path d="M20 11.943C20 7.562 16.424 4 12 4c-4.424 0-8 3.562-8 7.943 0 1.19.147 1.955.328 2.516.122.372.413.005.557-.13a2.5 2.5 0 0 1 3.472.05c1.421 1.412 2.732 3.37.921 5.17-.972.966-2.436 1.368-3.55.328-1.438-1.344-2.687-2.908-3.302-4.8C2.17 14.283 2 13.304 2 11.942 2 6.445 6.483 2 12 2s10 4.445 10 9.943c0 1.362-.169 2.341-.426 3.133-.615 1.893-1.864 3.457-3.302 4.8-1.114 1.041-2.578.64-3.55-.327-1.81-1.8-.5-3.758.92-5.17a2.5 2.5 0 0 1 3.473-.05c.277.26.414.57.557.13.181-.56.328-1.327.328-2.516Z"fill=currentColor></path></g></svg>'
            },
            {
                app: "voiceover",
                title: 'Texto para Fala',
                description: "Converta seus textos em fala realista",
                text: "Texto para fala",
                colors: ["#BCE143", "#30C862"],
                icon: '<svg class="drop-shadow-sm w-7/12" viewBox="0 0 24 24" xmlns=http://www.w3.org/2000/svg><g fill=none fill-rule=nonzero id=horn_fill><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"></path><path d="M19.149 2.328C19.995 1.863 21 2.489 21 3.426v17.148c0 .937-1.005 1.563-1.851 1.098-1.137-.624-4.503-2.442-6.518-3.243a18.916 18.916 0 0 0-3.877-1.1l-.773 3.867a1 1 0 1 1-1.962-.392l.743-3.715a28.37 28.37 0 0 0-.809-.049C4.35 16.966 3 15.671 3 14v-4c0-1.671 1.35-2.966 2.953-3.04.355-.017.704-.039 1.047-.068V16h.034a1 1 0 0 1 1.732 0H9V6.628a18.992 18.992 0 0 0 3.63-1.057c2.016-.801 5.382-2.62 6.519-3.243Z"fill=currentColor></path></g></svg>'
            },
            // {
            //     app: "voice-isolator",
            //     title: 'Isolador de voz',
            //     description: "Isole a voz do ruído de fundo.",
            //     text: "Removedor de ruído",
            //     colors: ["#6283FB", "#C883F3"],
            //     icon: '<svg class="drop-shadow-sm w-7/12"viewBox="0 0 24 24"xmlns=http://www.w3.org/2000/svg><g fill=none fill-rule=nonzero id=voice_fill><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z"></path><path d="M12 2.5a1.5 1.5 0 0 1 1.493 1.356L13.5 4v16a1.5 1.5 0 0 1-2.993.144L10.5 20V4A1.5 1.5 0 0 1 12 2.5Zm-4 3A1.5 1.5 0 0 1 9.5 7v10a1.5 1.5 0 0 1-3 0V7A1.5 1.5 0 0 1 8 5.5Zm8 0A1.5 1.5 0 0 1 17.5 7v10a1.5 1.5 0 0 1-3 0V7A1.5 1.5 0 0 1 16 5.5Zm-12 3A1.5 1.5 0 0 1 5.5 10v4a1.5 1.5 0 0 1-3 0v-4A1.5 1.5 0 0 1 4 8.5Zm16 0a1.5 1.5 0 0 1 1.493 1.356L21.5 10v4a1.5 1.5 0 0 1-2.993.144L18.5 14v-4A1.5 1.5 0 0 1 20 8.5Z"fill=currentColor></path></g></svg>'
            // },
            {
                app: "moderation",
                title: 'Classificação',
                description: "Verifique se o conteúdo pode ser potencialmente prejudicial em diversas categorias.",
                text: "Moderação",
                colors: ["#E562FB", "#F8C06D"],
                icon: '<svg class="drop-shadow-sm w-7/12" viewBox="0 0 24 24" xmlns=http://www.w3.org/2000/svg><g fill=none fill-rule=nonzero id=flag_2_fill><path d="M24 0v24H0V0zM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01z"></path><path d="M19 3.629a1.5 1.5 0 0 0-1.837-1.462L5.55 4.847A2 2 0 0 0 4 6.796V21a1 1 0 1 0 2 0v-4.204l11.45-2.643A2 2 0 0 0 19 12.204z"fill=currentColor></path></g></svg>'
            },
            {
                app: "jogg",
                title: 'Vídeos IA',
                description: "Gere vídeos de produtos ou de um texto com um avatar narrando",
                text: "Gerador de vídeo",
                colors: ["#5758fe", "#87a4fc"],
                icon: '<svg class="drop-shadow-sm w-7/12" viewBox="0 0 512 512" xmlns=http://www.w3.org/2000/svg><g fill=currentColor stroke=none transform="translate(0,512) scale(0.1,-0.1)"><path d="M2255 5029 c-162 -21 -359 -97 -497 -190 -185 -125 -340 -329 -411 -541 -60 -180 -62 -195 -62 -678 -1 -464 -5 -527 -51 -705 -52 -200 -161 -348 -312 -424 -23 -12 -42 -25 -42 -30 0 -14 92 -94 157 -137 170 -113 408 -169 802 -191 l121 -6 0  46 c0 -104 -46 -217 -137 -339 l-55 -74 44 -56 c286 -368 1062 -403 1429 -64 38 35 78 77 89 92 l21 29 -60 40 c-75 51 -108 94 -131 169 -18 57 -44 242 -35 250 2 2 59 8 127 13 423 30 652 76 828 164 68 34 170 105 170 117 0 4 -19 17 -42 29 -24 12 -77 56 -119 98 -62 61 -85 94 -122 171 -26 52 -59 142 -74 200 l-27 105 -1 505 c-1 539 -3 561 -56 742 -105 358 -372 568 -725 568 l-101 0 -69 46 c-90 61 -158 90 -273 120 -108 28 -278 38 -386 23z"/><path d="M3610 1693 c0 -58 -19 -160 -40 -221 -87 -248 -347 -429 -725 -504 -144 -29 -449 -31 -592 -5 -342 64 -594 206 -733 413 -49 74 -100 207 -100 262 0 60 -2 59 -241 -23 -398 -137 -601 -224 -752 -322 -204 -131 -278 -299 -353 -798 -32 -219 -56 -351 -70 -397 -5 -17 121 -18 2556 -18 2435 0 2561 1 2556 18 -14 46 -38 178 -70 397 -59 393 -125 594 -232 698 -141 140 -427 275 -972 461 -122 42 -224 76 -227 76 -3 0 -5 -17 -5 -37z"/></g></svg>'
            },
            {
                app: "suno",
                title: 'Músicas IA',
                description: "Gere músicas com letra ou somente um intrumental",
                text: "Gerador de música",
                colors: ["#ff6b6b", "#ffa07a"],
                icon: '<svg class="drop-shadow-sm w-7/12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M2 12c2-4 4-6 6-6s4 2 6 6 4 6 6 6 4-2 6-6" stroke="currentColor" stroke-width="2" fill="none"/></svg>'
            }
        ],
        iaChatModels: [
            {
                model: "o1-mini",
                name: "OpenAI / O1 Mini",
                internet: true
            },
            {
                model: "o3-mini",
                name: "OpenAI / O3 Mini",
                internet: true
            },
            {
                model: "gpt-4o",
                name: "OpenAI / GPT-4o",
                internet: true
            },
            {
                model: "gpt-4o-mini",
                name: "OpenAI / GPT-4o Mini",
                internet: true
            },
            {
                model: "gpt-3-5-turbo",
                name: "OpenAI / GPT-3.5 Turbo",
                internet: true
            },
            {
                model: "gemini-1-5-pro",
                name: "Gemini / 1.5 Pro",
                internet: true
            },
            {
                model: "gemini-1-5-flash",
                name: "Gemini / 1.5 Flash",
                internet: true
            },
            {
                model: "gemini-2-0-flash-exp",
                name: "Gemini / 2.0 Flash Exp",
                internet: true
            },
            {
                model: "command-r",
                name: "CoHere / Command R",
                internet: true
            },
            {
                model: "command-r-plus",
                name: "CodeHere / Command R+",
                internet: true
            },
            {
                model: "deepseek/deepseek-r1",
                name: "DeepSeek R1",
                internet: false
            },
            {
                model: "deepseek/deepseek-r1-distill-llama-70b",
                name: "DeepSeek R1 Distill",
                internet: false
            },
            {
                model: "deepseek/deepseek-chat",
                name: "DeepSeek v3"
            },
            {
                model: "meta-llama/llama-3.3-70b-instruct", // meta-llama/llama-3.3-70b-instruct
                name: "Meta Llama 3.3",
                internet: true
            },
            {
                model: "meta-llama/llama-3.1-8b-instruct", // meta-llama/llama-3.1-8b-instruct
                name: "Meta Llama 3.1",
                internet: true
            },
            {
                model: "mistralai/mistral-small-3.1-24b-instruct", // mistralai/mistral-small-3.1-24b-instruct
                name: "Mistral",
                internet: false
            },
            {
                model: "microsoft/phi-4",
                name: "Microsoft Phi-4",
                internet: false
            },
            {
                model: "qwen/qwen-2.5-coder-32b-instruct", // qwen/qwen-2.5-coder-32b-instruct
                name: "Qwen Coder",
                internet: false
            },
            {
                model: "microsoft/wizardlm-2-8x22b",
                name: "Microsoft WizardLM",
                internet: false
            }
        ],
        services: [
            // {
            //     index: "service1",
            //     name: "Serviço 1",
            //     url: "https://loomisweb.com.br/apps/1/"
            // },
            // {
            //     index: "service2",
            //     name: "Serviço 2",
            //     url: "https://loomisweb.com.br/apps/2/"
            // },
            // {
            //     index: "service3",
            //     name: "Serviço 3",
            //     url: "https://loomisweb.com.br/apps/3/"
            // }
        ],
        iaChatModelSelected: "gpt-4o-mini"
    },
    getters: {
        isDark: state => state.isDark,
        companieId: state => state.companieId,
        companieData: state => state.companieData,
        apiURL: state => state.apiURL,
        showMenu: state => state.showMenu,
        assistantId: state => state.assistantId,
        voice: state => state.voice,
        toolsCategories: state => state.toolsCategories,
        iaCategories: state => state.iaCategories,
        iaChatModels: state => state.iaChatModels,
        services: state => state.services,
        iaChatModelSelected: state => state.iaChatModelSelected
    },
    mutations: {
        toggleTheme(state) {
            state.isDark = !state.isDark;
        },
        setCompanieId(state, payload) {
            state.companieId = payload;
        },
        setCompanieData(state, payload) {
            state.companieData = payload;
        },
        setShowMenu(state, payload) {
            state.showMenu = payload;
        },
        setAssistantId(state, payload) {
            state.assistantId = payload;
        },
        setVoice(state, payload) {
            state.voice = payload;
        },
        setIaChatModelSelected(state, payload) {
            state.iaChatModelSelected = payload;
        },
    },
    actions: {
        toggleTheme({ commit }) {
            commit('toggleTheme');
        }
    },
    modules: {
        auth
    },
    plugins: [
        createPersistedState({
            key: 'isDark',
            storage: window.localStorage,
            paths: ['isDark'],
        }),
        createPersistedState({
            key: 'companieId',
            storage: window.localStorage,
            paths: ['companieId'],
        }),
        createPersistedState({
            key: 'companieData',
            storage: window.localStorage,
            paths: ['companieData'],
        }),
    ]
})
