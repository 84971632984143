<template>
    <div class="p-6">
        <h1 class="text-3xl font-bold">Minhas Empresas</h1>
        <ul class="mt-6 grid lg:grid-cols-4 gap-8" v-if="this.currentUser">
            <li class="rounded relative bg-neutral-200 dark:bg-neutral-900 p-4" v-for="(item, index) in companies"
                :key="index">
                <button class="absolute right-5 text-red-600 z-20">
                    <font-awesome-icon :icon="['fas', 'trash']" class="absolute right-0" v-if="checkOwner(index)"
                        @click="confirmDelete(index)" />
                    <u v-if="!checkOwner(index)" class="absolute right-0"  @click="confirmLeave(index)">Sair</u>
                </button>
                <div class="absolute bottom-0 right-0 z-0">
                    <div class="h-32">
                        <img src="https://loomisweb.com.br/cdn-files/lw-hub/empresa-logo.png" class="h-full">
                    </div>
                </div>
                <div class="relative rounded text-neutral-400 cursor-pointer z-10" @click="selectCompanie(index)">
                    <div class="flex items-center gap-2 mb-8">
                        <h1 class="text-black dark:text-white font-bold">{{ item.name }}</h1>
                        <span 
                            class="py-1 px-2 inline-block text-sm rounded-[6px] text-center bg-opacity-20 text-white" 
                            :class="{ 'bg-blue-800' : item.Product, 'bg-red-800' : !item.Product }"
                        >
                            {{ (item.Product) ? `${item.Product.name} - ${this.$utils.formartDate(item.expire, true)}` : 'Sem plano' }}
                        </span>
                    </div>
                    <!-- <hr class="border-neutral-300 dark:border-neutral-600 my-4"> -->
                    <div v-if="item.Product">
                        <div class="flex justify-between">
                            <h3>Créditos Plano</h3>
                        </div>
                        <span class="text-black dark:text-white font-bold text-3xl">{{ item.credits_plan }}</span>
                    </div>
                    <div>
                        <div class="flex justify-between">
                            <h3>Créditos Adicionais</h3>
                        </div>
                        <span class="text-black dark:text-white font-bold text-3xl">{{ item.credits }}</span>
                    </div>
                </div>
            </li>
            <li class="bg-purple-700 p-16 text-white flex flex-col items-center justify-center rounded cursor-pointer" @click="showCreate = !showCreate">
                <font-awesome-icon :icon="['fas', 'plus']" class="text-4xl" />
                <p class="text-2xl font-bold">Criar Empresa</p>
            </li>
        </ul>
    </div>
    <div class="side-modal fixed inset-0 z-[1] bg-black/50" v-if="showCreate">
        <aside class="fixed inset-0 bg-white dark:bg-[#131313] md:left-auto md:max-w-md w-full overflow-scroll scrollbar-hide">
            <div class="text-right p-5">
                <button class="text-xl" @click="this.showCreate = !this.showCreate">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
            </div>
            <div class="p-5 space-y-4">
                <div class="text-2xl font-semibold">Criar Empresa</div>
                <Form @submit="handleCreate" :validation-schema="companieSchema" class="space-y-4">
                    <label class="block" for="name">
                        <span
                            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 dark:text-white">
                            Nome da Empresa
                        </span>
                        <Field type="text" v-model="name" id="name" name="name"
                            class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 dark:bg-neutral-900 dark:border-neutral-600" />
                        <ErrorMessage name="name" class="text-red-500 text-sm mt-1" />
                    </label>
                    <label class="block" for="email">
                        <span
                            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 dark:text-white">
                            E-mail
                        </span>
                        <Field type="email" v-model="email" id="email" name="email"
                            class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 dark:bg-neutral-900 dark:border-neutral-600" />
                        <ErrorMessage name="email" class="text-red-500 text-sm mt-1" />
                    </label>
                    <label class="block" for="cpforcnpj">
                        <span
                            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 dark:text-white">
                            CPF/CNPJ
                        </span>
                        <Field type="text" id="cpforcnpj" name="cpforcnpj" v-model="cpforcnpj" v-maska data-maska="[
                                    '###.###.###-##',
                                    '##.###.###/####-##'
                                ]"
                            class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 dark:bg-neutral-900 dark:border-neutral-600" />
                        <ErrorMessage name="cpforcnpj" class="text-red-500 text-sm mt-1" />
                    </label>
                    <label class="block" for="phone">
                        <span
                            class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700 dark:text-white">
                            Telefone
                        </span>
                        <Field type="text" id="phone" name="phone" v-model="phone" v-maska data-maska="[
                                    '(##) ####-####',
                                    '(##) #####-####'
                                ]"
                            class="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1 dark:bg-neutral-900 dark:border-neutral-600" />
                        <ErrorMessage name="phone" class="text-red-500 text-sm mt-1" />
                    </label>
                    <button class="rounded-xl text-center text-white py-4 px-8 text-base bg-purple-700 w-full mt-2"
                        type="submit" :disabled="loading">
                        <font-awesome-icon :icon="['fas', 'circle-notch']" class="animate-spin" v-if="loading" />
                        Criar Empresa
                    </button>
                </Form>
            </div>
        </aside>
    </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import companieService from "@/services/companie.service";
import { mapMutations } from 'vuex';

export default {
    name: 'HomeView',
    components: {
        Form,
        Field,
        ErrorMessage
    },
    data() {
        return {
            companies: [],
            showCreate: false,
            name: null,
            email: null,
            cpforcnpj: null,
            phone: null,
            loading: false,
            companieSchema: yup.object({
                name: yup
                    .string()
                    .required("O nome é obrigatório!")
                    .min(3, "Deve ter pelo menos 3 caracteres!")
                    .max(255, "Deve ter no máximo 255 caracteres!"),
                email: yup
                    .string()
                    .email("Digite um email válido")
                    .required("O email é obrigatório")
                    .max(255, "Deve ter no máximo 255 caracteres!"),
                cpforcnpj: yup
                    .string()
                    .required("CPF/CNPJ é obrigatório")
                    .test("cpf-or-cnpj", "CPF ou CNPJ inválido", (value) => this.validateCpfOrCnpj(value)),
                phone: yup
                    .string()
                    .required("Telefone é obrigatório")
                    .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, "Telefone deve estar no formato (99) 99999-9999"),
            })
        }
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        ...mapMutations(["setCompanieId", "setCompanieData"]),
        validateCpfOrCnpj(value) {
            const isValidCpf = (cpf) => {
                return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(cpf);
            };
            const isValidCnpj = (cnpj) => {
                return /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(cnpj);
            };
            return isValidCpf(value) || isValidCnpj(value);
        },
        selectCompanie(index) {
            if (this.companies[index]) {
                const companie = this.companies[index];

                companieService.read({ companie: companie.id }).then(
                    (response) => {
                        this.setCompanieData(response.data.companie)
                        this.setCompanieId(companie.id)
                        return this.$router.push(`/companies/${companie.id}`)
                    },
                    (error) => {
                        const message = error.response?.data?.message ?? error.message ?? error.toString();
                        this.$utils.toast(message, {
                            theme: "auto",
                            type: "error"
                        });
                    }
                )
            }
        },
        handleCreate(data) {
            data.cpforcnpj = this.$utils.removeMask(data.cpforcnpj);
            data.phone = this.$utils.removeMask(data.phone);

            this.loading = true;
            companieService.create(data).then(
                (response) => {
                    this.$utils.toast(response.data.message, {
                        theme: "auto",
                        type: "success"
                    });
                    this.loading = false;
                    this.fetchCompanies();
                    this.showCreate = false;
                },
                (error) => {
                    const message = error.response?.data?.message ?? error.message ?? error.toString();
                    this.loading = false;
                    this.$utils.toast(message, {
                        theme: "auto",
                        type: "error"
                    });
                }
            );
        },
        fetchCompanies() {
            companieService.list().then(
                (response) => {
                    this.companies = response.data.companies;
                },
                (error) => {
                    const message = error.response?.data?.message ?? error.message ?? error.toString();
                    this.$utils.toast(message, {
                        theme: "auto",
                        type: "error"
                    });
                }
            )
        },
        checkOwner(index) {
            if (this.companies[index]) {
                const companie = this.companies[index];

                if (companie.userId == this.currentUser.id) {
                    return true
                }
            }

            return false
        },
        confirmDelete(index) {
            if (this.companies[index]) {
                const item = this.companies[index];
                this.$utils.swaldelete().then((result) => {
                    if (result.isConfirmed) {
                        this.deleteCompanie(item.id);
                    }
                });
            }
        },
        async deleteCompanie(id) {
            this.$utils.toastpromise(
                companieService.updateDeleted({ id: id, deleted: true }),
                {
                    pending: `Excluindo empresa #${id}...`,
                    success: "Empresa excluída com sucesso!",
                    error: "Erro ao excluir a empresa.",
                },
                {
                    theme: "auto",
                    autoClose: 5000,
                    closeOnClick: false,
                }
            ).then(() => {
                this.fetchCompanies();
            }).catch((error) => {
                console.error("Erro durante a exclusão:", error);
            });
        },
        confirmLeave(index) {
            if (this.companies[index]) {
                const item = this.companies[index];
                this.$utils.swaldelete().then((result) => {
                    if (result.isConfirmed) {
                        this.leaveCompanie(item.id);
                    }
                });
            }
        },
        async leaveCompanie(id) {
            this.$utils.toastpromise(
                companieService.leaveCompanie({ id: id }),
                {
                    pending: `Saindo da empresa #${id}...`,
                    success: "Você saiu da empresa com sucesso!",
                    error: "Erro ao sair da empresa.",
                },
                {
                    theme: "auto",
                    autoClose: 5000,
                    closeOnClick: false,
                }
            ).then(() => {
                this.fetchCompanies();
            }).catch((error) => {
                console.error("Erro durante a exclusão:", error);
            });
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        } else {
            this.fetchCompanies()
        }
    }
}
</script>
