<template>
    <div 
        class="fixed inset-0 z-10 px-5 py-6 xl:w-72 xl:static flex-col h-full bg-neutral-900 border-r border-neutral-600 overflow-y-auto select-none xl:flex"
        :class="(this.showMenu) ? 'flex z-20' : 'hidden'"
    >
        <button class="xl:hidden w-8 h-8 flex-shrink-0 flex-center ml-auto mb-4 border border-gray-700 rounded-lg" @click="setShowMenu(false)">
            <font-awesome-icon :icon="['fas', 'xmark']" />
        </button>

        <div class="flex flex-col items-center mb-8">
            <img src="/logo-white.png" alt="Logo da Empresa" class="w-40 mb-2" />
        </div>

        <ul class="space-y-4 mb-4">
            <template v-for="(item, index) in menu" :key="index">
                <li class="text-gray-300 font-bold flex items-center cursor-pointer" @click="activeMenu(index)">
                    <font-awesome-icon :icon="item.icon" />
                    <span class="ml-4">{{ item.name }}</span>
                    <font-awesome-icon :icon="['fas', 'chevron-up']" class="text-sm transition-transform ml-auto" :class="{ 'rotate-180' : !item.active }" />
                </li>
                <section class="flex flex-col items-stretch">
                    <button 
                        v-for="(link, linkindex) in item.links" :key="linkindex" v-show="item.active"
                        :class="{ 
                            'px-3 py-3 rounded-xl text-sm text-left border-b-2 border-purple-800 text-white bg-purple-700': isActive(index, linkindex),
                            'text-gray-300 px-3 py-3 rounded-xl text-sm text-left': !isActive(index, linkindex)
                        }"
                        @click="toRoute(index, linkindex)"
                    >{{ link.name }}</button>
                </section>
            </template>
        </ul>
        <button class="mt-auto flex items-center h-14 text-sm p-4 space-x-4 bg-neutral-800 rounded-lg border border-neutral-600 text-left text-white" @click="$router.push('/')">
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
            <span>Voltar</span>
        </button>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: "MenuAdminComponent",
    data() {
        return {
            menu: [
                {
                    name: "Dashboard",
                    icon: ['fas', 'house'],
                    active: true,
                    links: [
                        {
                            name: 'Resumo',
                            route: '/admin'
                        }
                    ]
                },
                {
                    name: "Empresas",
                    icon: ['fas', 'building'],
                    active: false,
                    links: [
                        {
                            name: 'Todas as empresas',
                            route: '/admin/companies'
                        }
                    ]
                },
                {
                    name: "Produtos",
                    icon: ['fas', 'cart-shopping'],
                    active: false,
                    links: [
                        {
                            name: 'Todas os produtos',
                            route: '/admin/products'
                        }
                    ]
                },
                {
                    name: "Pedidos",
                    icon: ['fas', 'store'],
                    active: false,
                    links: [
                        {
                            name: 'Todas os pedidos',
                            route: '/admin/orders'
                        }
                    ]
                },
                {
                    name: "Assistentes",
                    icon: ['fas', 'robot'],
                    active: false,
                    links: [
                        {
                            name: 'Todas os assistentes',
                            route: '/admin/assistents'
                        }
                    ]
                },
                {
                    name: "Usuários",
                    icon: ['fas', 'users'],
                    active: false,
                    links: [
                        {
                            name: 'Todos os usuários',
                            route: '/admin/users'
                        }
                    ]
                },
                {
                    name: "Formulários",
                    icon: ['fas', 'file-lines'],
                    active: false,
                    links: [
                        {
                            name: 'Todos os formulários',
                            route: '/admin/forms'
                        }
                    ]
                },
                {
                    name: "Avatars Custom",
                    icon: ['fas', 'user'],
                    active: false,
                    links: [
                        {
                            name: 'Todos os avatars',
                            route: '/admin/customavatars'
                        }
                    ]
                },
                {
                    name: "Vozes Custom",
                    icon: ['fas', 'microphone-lines'],
                    active: false,
                    links: [
                        {
                            name: 'Todas as vozes',
                            route: '/admin/customvoices'
                        }
                    ]
                },
                {
                    name: "Configurações",
                    icon: ['fas', 'gears'],
                    active: false,
                    links: [
                        {
                            name: 'Todos as Configurações',
                            route: '/admin/settings'
                        }
                    ]
                }
            ]
        }
    },
    computed: {
        ...mapGetters(["storeId","showMenu"])
    },
    methods: {
        ...mapMutations(["setShowMenu"]),
        activeMenu(index) {
            if (this.menu[index]) {
                this.menu[index].active = !this.menu[index].active;
            }
        },
        isActive(index, linkindex) {
            if (this.menu[index]) {
                const item = this.menu[index];

                if (item.links[linkindex]) {
                    const link = item.links[linkindex];

                    return this.$route.path === `${link.route}`
                }
            }
        },
        toRoute(index, linkindex) {
            if (this.menu[index]) {
                const item = this.menu[index];

                if (item.links[linkindex]) {
                    const link = item.links[linkindex];

                    return this.$router.push(`${link.route}`)
                }
            }
        }
    }
}
</script>