import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { FontAwesomeIcon } from './plugins/font-awesome'
import { vMaska } from "maska/vue"
import Utils from './plugins/utils'
import { WaveElement } from './plugins/wave'
import VueLazyLoad from 'vue3-lazyload'
import { masks } from './plugins/masks'
import './main.css'; // Importa o Tailwind CSS

customElements.define('x-wave', WaveElement);

import Alpine from "alpinejs";
import Tooltip from "@ryangjchandler/alpine-tooltip";

Alpine.plugin(Tooltip);

window.Alpine = Alpine;
window.Alpine.start();

createApp(App)
    .use(store)
    .use(router)
    .use(Utils)
    .use(VueLazyLoad)
    .component("font-awesome-icon", FontAwesomeIcon)
    .directive("maska", vMaska)
    .directive('saldo-mask', masks.saldoMask)
    .mount('#app')
