export const masks = {
    saldoMask: {
        beforeMount(el) {
            el.addEventListener("input", function (e) {
                let value = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                value = value.replace(/^0+/, "");
    
                if (value.length === 1) {
                    value = "0,0" + value;
                } else if (value.length === 2) {
                    value = "0," + value;
                } else {
                    value = value.replace(/(\d+)(\d{2})$/, "$1,$2");
                }
    
                value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Adiciona os pontos de milhar
    
                e.target.value = value; // Atualiza o valor do campo de input

                const event = new Event("input", { bubbles: true });
                event.value = value;
                e.target.dispatchEvent(event);
            });
        }
    }
};
