import api from '@/api/axios';
import authHeader from './auth-header';

class AuthService {
    login(user) {
        return api
        .post('auth/signin', {
            email: user.email,
            password: user.password,
            token: user.token
        })
        .then(response => {
            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }

            return response.data;
        });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return api.post('auth/signup', {
            name: user.name,
            email: user.email,
            password: user.password,
            cpf: user.cpf,
            phone: user.phone,
            token: user.token
        });
    }

    verifyUser(code){
        console.log('verifyUser', code)
        return api
        .get(`auth/confirm/${code}`)
        .then(response => {
            return response;
        });
    }

    changePassword(user) {
        return api.post('auth/change-password', {
            password: user.password,
            newpassword: user.newpassword
        }, { headers: authHeader() })
    }

    uploadProfileImage(formData) {
        return api.post('user/image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader()
            },
        }).then(response => {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.image = response.data.image;
                localStorage.setItem('user', JSON.stringify(user));
            }
            return response.data;
        });
    }

    forget(data) {
        return api.post("auth/forget", data)
    }

    forgetCode(data) {
        return api.post("auth/forget-code", data)
    }

    forgetChangePassword(data) {
        return api.post("auth/forget-password", data)
    }
}

export default new AuthService();