import { toast } from 'vue3-toastify';
import "vue3-toastify/dist/index.css";
import Swal from 'sweetalert2';

class Utils {
    static generateImage(name, darkmode, background, color) {
        const canvas = document.createElement('canvas');
        canvas.width = 100;
        canvas.height = 100;

        const ctx = canvas.getContext('2d');

        if (!background) {
            background = (darkmode) ? '#333' : '#dbdbdb'
        }

        if (!color) {
            color = (darkmode) ? '#fff' : '#333'
        }

        ctx.fillStyle = background; // Cor preta
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // Define o estilo do texto
        ctx.fillStyle = color; // Cor branca
        ctx.font = 'bold 4.8vh Arial'; // Tamanho e fonte do texto

        const namefiltered = this.filterName(name).toUpperCase();

        const textWidth = ctx.measureText(namefiltered).width;
        const textX = (canvas.width - textWidth) / 2;
        const textY = (canvas.height + 35) / 2; // 48 é o tamanho da fonte

        ctx.fillText(namefiltered, textX, textY);

        const dataUrl = canvas.toDataURL();

        return dataUrl
    }

    static filterName(fullName) {
        fullName = fullName.trim();

        const pattern = /^[A-Za-z]/;
        const words = fullName.split(' ');

        for (const word of words) {
            if (pattern.test(word)) {
                return word.charAt(0).toUpperCase();
            }
        }

        return '';
    }

    static formatDateAgo(dateString) {
        const date = new Date(dateString);
        const now = new Date();
        const diff = now - date;

        // Calcula o tempo em segundos
        const seconds = Math.floor(diff / 1000);

        if (seconds < 60) {
            return seconds === 1 ? "1 segundo atrás" : `${seconds} segundos atrás`;
        }

        // Calcula o tempo em minutos
        const minutes = Math.floor(seconds / 60);

        if (minutes < 60) {
            return minutes === 1 ? "1 minuto atrás" : `${minutes} minutos atrás`;
        }

        // Calcula o tempo em horas
        const hours = Math.floor(minutes / 60);

        if (hours < 24) {
            return hours === 1 ? "1 hora atrás" : `${hours} horas atrás`;
        }

        // Calcula o tempo em dias
        const days = Math.floor(hours / 24);

        if (days < 30) {
            return days === 1 ? "1 dia atrás" : `${days} dias atrás`;
        }

        // Calcula o tempo em meses
        const months = Math.floor(days / 30);

        if (months < 12) {
            return months === 1 ? "1 mês atrás" : `${months} meses atrás`;
        }

        // Calcula o tempo em anos
        const years = Math.floor(months / 12);
        return years === 1 ? "1 ano atrás" : `${years} anos atrás`;
    }

    static getFormatedTime(time) {
        let newtime = new Date(time);

        let hours = newtime.getHours();
        let minutes = newtime.getMinutes();

        if (hours < 10) {
            hours = "0" + hours
        }

        if (minutes < 10) {
            minutes = "0" + minutes
        }

        return `${hours}:${minutes}`
    }

    static formatNumberToK(number) {
        if (number >= 1000) {
            const formattedNumber = (number / 1000).toFixed(1);
            return `${formattedNumber}K`;
        }
        return number.toString();
    }

    static limitText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        } else {
            return text.substring(0, maxLength) + '...';
        }
    }

    static formartPrice(price) {
        if (typeof (price) != "number") {
            price = parseInt(price)
        }

        return (price).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
        });
    }

    static formartPriceReal(price) {
        if (typeof price !== "number") {
            price = parseFloat(price); // Use parseFloat para converter strings que representam números decimais
        }

        return price.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    }

    static formartNumber(price) {
        if (typeof (price) != "number") {
            price = parseInt(price)
        }

        return (price).toLocaleString();
    }

    static formartDate(date, hour) {
        if (date != null) {
            var formatdate = new Date(date);
            if (hour) {
                return formatdate.toLocaleDateString('pt-BR') + " às " + formatdate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
            } else {
                return formatdate.toLocaleDateString('pt-BR');
            }
        } else {
            return "Não encontrado"
        }
    }

    static dateFormatted(date) {
        var formatdate = new Date(date);
        var day = formatdate.getDate();
        var month = formatdate.getMonth() + 1;
        var year = formatdate.getFullYear();
        return `${year}-${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}`;
    }

    static dateFormattedMysql(date) {
        var formatdate = new Date(date);
        var day = formatdate.getDate();
        var month = formatdate.getMonth() + 1;
        var year = formatdate.getFullYear();
        return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }

    static formatTime(secondsTotal) {
        const hours = Math.floor(secondsTotal / 3600);
        const minutesRemaining = secondsTotal % 3600;
        const minutes = Math.floor(minutesRemaining / 60);
        const seconds = minutesRemaining % 60;

        function twoDigits(number) {
            return number.toString().padStart(2, '0');
        }

        if (hours > 0) {
            return `${twoDigits(hours)}:${twoDigits(minutes)}:${twoDigits(seconds)}`;
        }

        return `${twoDigits(minutes)}:${twoDigits(seconds)}`;
    }

    static formatTextLineBreaks(text) {
        return text.replace(/\n/g, "<br><br>")
    }

    static rgbToHex(r, g, b) {
        const toHex = (c) => {
            const hex = c.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        };

        const red = toHex(r);
        const green = toHex(g);
        const blue = toHex(b);

        return `#${red}${green}${blue}`;
    }

    static secondsToTime(totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = parseInt(totalSeconds % 60);

        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");

        if (hours > 0) {
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            return `${formattedMinutes}:${formattedSeconds}`;
        }
    }

    static getMonthAndDay(dateString) {
        const months = [
            "Jan", "Fev", "Mar", "Abr", "Mai", "Jun",
            "Jul", "Ago", "Set", "Out", "Nov", "Dez"
        ];

        const date = new Date(dateString);
        const day = date.getDate();
        const monthName = months[date.getMonth()];

        return `${monthName} ${day}`;
    }

    static getHourAndMinutes(dateString) {
        const date = new Date(dateString);
        const hour = date.getHours();
        const minutes = date.getMinutes();

        return `${hour}:${minutes < 10 ? "0" + minutes : minutes}`;
    }

    static isImageLink(link) {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const filename = link.split('/').pop(); // Extrai o nome do arquivo do link
        const extension = filename.split('.').pop().toLowerCase(); // Extrai a extensão e converte para minúsculas
        return imageExtensions.includes(extension);
    }

    static isVideoLink(link) {
        const videoExtensions = ['webm', 'mp4', 'avi'];
        const filename = link.split('/').pop(); // Extrai o nome do arquivo do link
        const extension = filename.split('.').pop().toLowerCase(); // Extrai a extensão e converte para minúsculas
        return videoExtensions.includes(extension);
    }

    static maskCPF(value) {
        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');

        // Formata o CPF: 000.***.***-**
        if (value.length >= 11) {
            return `${value.slice(0, 3)}.${'***'}.${'***'}-${value.slice(9)}`;
        }
        return value; // Retorna o valor original se não for um CPF válido
    }

    static maskPhone(value) {
        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');

        // Formata o telefone: (00) 00000-****
        if (value.length >= 11) {
            return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${'****'}`;
        }
        return value; // Retorna o valor original se não for um telefone válido
    }

    static maskCEP(value) {
        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');

        // Formata o CEP: 00000-000
        if (value.length >= 8) {
            return `${value.slice(0, 5)}-${value.slice(5, 8)}`;
        }
        return value; // Retorna o valor original se não for um CEP válido
    }

    static maskCPFReal(value) {
        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');

        // Formata o CPF: 000.000.000-00
        if (value.length >= 11) {
            return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}-${value.slice(9)}`;
        }
        return value; // Retorna o valor original se não for um CPF válido
    }

    static maskPhoneReal(value) {
        // Remove caracteres não numéricos
        value = value.replace(/\D/g, '');

        // Formata o telefone: (00) 00000-0000
        if (value.length >= 11) {
            return `(${value.slice(0, 2)}) ${value.slice(2, 7)}-${value.slice(7)}`;
        }
        return value; // Retorna o valor original se não for um telefone válido
    }

    static removeMask(value) {
        return value.replace(/\D/g, '');
    }

    static toast(message, options) {
        return toast(message, options)
    }

    static toastpromise(promise, params, options) {
        return toast.promise(promise, params, options)
    }

    static swal(options) {
        return Swal.fire(options)
    }

    static swaldelete() {
        return this.swal({
            title: 'Você tem certeza?',
            text: "Essa ação não poderá ser desfeita!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sim, excluir!',
            cancelButtonText: 'Não, cancelar'
        })
    }

    static copyText(text) {
        try {
            navigator.clipboard.writeText(text);
            this.toast("Texto copiado", {
                theme: "auto",
                type: "success"
            });
        } catch (err) {
            this.toast("Erro ao copiar texto", {
                theme: "auto",
                type: "success"
            });
            console.error("Erro ao copiar texto:", err);
        }
    }

    static download(title, text, format) {
        let mimeType = 'text/plain';
        let ext = 'txt';
        let content = text;

        if (format == 'markdown') {
            mimeType = 'text/markdown';
            ext = 'md';
            content = text;
        } else if (format == 'html') {
            mimeType = 'text/html';
            ext = 'html';
            content = `<html><head><meta charset="utf-8" /><title>${title}</title></head><body>${text}</body></html>`;
        } else if (format == '"word"') {
            mimeType = 'application/vnd.ms-word';
            ext = 'doc';
            content = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:w="urn:schemas-microsoft-com:office:word" xmlns="http://www.w3.org/TR/REC-html40"><head><meta charset="utf-8" /><title>${title}</title></head><body>${text}</body></html>`;
        }

        this.downloadFromUrl(
            `data:${mimeType};charset=utf-8,${encodeURIComponent(content)}`,
            title,
            ext);
    }

    static downloadFromUrl(url, filename, ext) {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `${filename}.${ext}`;

        document.body.appendChild(anchor);
        anchor.click();

        // Clean up
        document.body.removeChild(anchor);
    }

    static isAdmin(user) {
        if (user && user['roles']) {
            return user['roles'].includes('ROLE_ADMIN');
        }
        return false;
    }

    static isModerator(user) {
        if (user && user['roles']) {
            return user['roles'].includes('ROLE_MODERATOR');
        }
        return false;
    }

    static isUser(user) {
        if (user && user['roles']) {
            return user['roles'].includes('ROLE_USER');
        }
        return false;
    }

    static isAdminOrModerator(user) {
        if (user && user['roles']) {
            return user['roles'].includes('ROLE_ADMIN') || user['roles'].includes('ROLE_MODERATOR');
        }
        return false;
    }

    static getDomainURL(url) {
        try {
            const urlObj = new URL(url);
            return urlObj.hostname.replace(/^www\./, '');
        } catch (e) {
            return 'Invalid URL';
        }
    }

    static getFaviconURL(url) {
        // Função auxiliar para obter o host da URL
        function getHost(url) {
            try {
                return new URL(url).hostname;
            } catch (e) {
                return null;
            }
        }

        // Tenta obter o host da URL fornecida
        let host = getHost(url);
        if (!host) {
            // Se não conseguir, tenta adicionar 'http://' e obter o host novamente
            host = getHost('http://' + url);
        }

        // Retorna a URL do favicon
        return 'https://icons.duckduckgo.com/ip3/' + host + '.ico';
    }

    static generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    static pf(phone) {
        let phoneWa = phone.replace(/\D/g, '');

        /* put all phone numbers in here if you don't want to be formatted! */
        // const exceptions = ['5542920015920', '5542920015921'];
        // if (exceptions.includes(phoneWa)) return phoneWa;

        if (phoneWa === '') {
            // DO NOTHING
            //
            /* Indonesia formatting */
        } else if (phoneWa.substr(0, 2) == '08') {
            phoneWa = phoneWa.replace(/08/, '628');
        } else if (phoneWa.substr(0, 4) == '6208') {
            phoneWa = phoneWa.replace(/6208/, '628');
            /* Indonesia formatting */

            /* Italy formatting */
        } else if (phoneWa.substr(0, 1) == '3' && (phoneWa.length === 9 || phoneWa.length === 10)) {
            phoneWa = '39' + phoneWa;
            /* Italy formatting */

            /* Nigeria formatting */
        } else if (phoneWa.substr(0, 4) == '2340') {
            phoneWa = phoneWa.replace(/2340/, '234');
            /* Nigeria formatting */

            /* Mexico formatting */
        } else if (phoneWa.substr(0, 2) == '52' && phoneWa.substr(2, 1) != '1') {
            phoneWa = phoneWa.replace(/52/, '521');
            /* Mexico formatting */

            /* Argentina formatting */
        } else if (phoneWa.substr(0, 2) == '54' && phoneWa.substr(2, 1) != '9') {
            phoneWa = phoneWa.replace(/54/, '549');
            /* Argentina formatting */

            /* Brazil formatting */
        } else if (phoneWa.substr(0, 2) == '55' && phoneWa.length == 13) {
            let ddd = parseInt(phoneWa.substr(2, 2));
            if (ddd > 30) {
                phoneWa = '55' + ddd + phoneWa.substr(-8);
            }
            /* Brazil formatting */
        }
        return phoneWa;
    }

    static s_wa(phone) {
        return `${this.pf(phone)}@s.whatsapp.net`;
    }
      
    static g_us(phone) {
        return `${this.pf(phone)}@g.us`;
    }
      
    static c_us(phone) {
        return `${this.pf(phone)}@c.us`;
    }

    static parseLanguages(languagesString) {
        // Quebrar a string de idiomas
        const languageCodes = languagesString.split(',');

        // Para cada código de idioma, quebramos em partes (linguagem, país) e buscamos o nome
        return languageCodes.map(code => {
            const parts = code.split('-');
            const language_code = parts[0];
            const country_code = parts[1] || null;

            // Usando Intl.DisplayNames se disponível no navegador
            const name = new Intl.DisplayNames(['en'], { type: 'language' }).of(language_code);
            const country_name = country_code ? new Intl.DisplayNames(['en'], { type: 'region' }).of(country_code) : null;

            return {
                code,
                language_code,
                country_code,
                country_name,
                name
            };
        });
    }

    static checkPermission(permission, permissions) {
        const hasPermission = permissions.some(company => 
            company.categories.some(category => 
                category.values.some(value => value.model === permission && value.value === true)
            )
        );

        return hasPermission
    }
}

export { Utils };

export default {
    install(app) {
        app.config.globalProperties.$utils = Utils;
    },
}