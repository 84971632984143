import api from "@/api/axios";
import authHeader from "./auth-header";

class CompanieService {
    list() {
        return api.get("companies/all", { headers: authHeader() })
    }

    dashboard(params) {
        return api.get("companies/dashboard", { params: params, headers: authHeader() })
    }

    create(data) {
        return api.post("companies/create", data, { headers: authHeader() })
    }

    planFree(data) {
        return api.post("companies/planfree", data, { headers: authHeader() })
    }

    leaveCompanie(data) {
        return api.post("companies/leavecompanie", data, { headers: authHeader() })
    }

    read(params) {
        return api.get("companies/read",{
            params: params,
            headers: authHeader()
        });
    }

    update(data) {
        return api.put("companies/update", data, { headers: authHeader() })
    }

    updateDeleted(data) {
        return api.put("companies/update-deleted", data, { headers: authHeader() })
    }

    uploadFile(formData) {
        return api.post("companies/upload-file", formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                ...authHeader()
            }
        });
    }
    
    delete(params){
        return api.delete("companies/delete",{
            params: params,
            headers: authHeader()
        })
    }
}

export default new CompanieService()