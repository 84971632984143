import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store';
import { Utils } from '@/plugins/utils';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/RegisterView.vue')
    },
    {
        path: '/forget',
        name: 'forget',
        component: () => import('../views/ForgetView.vue')
    },
    {
        path: '/confirm/:code',
        name: 'confirm',
        component: () => import('../views/ConfirmView.vue')
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('../views/AuthView.vue')
    },
    {
        path: '/config',
        name: 'config',
        component: () => import('../views/ConfigView.vue')
    },
    {
        path: '/access-denied',
        name: 'access-denied',
        component: () => import('../views/AcessDeniedView.vue')
    },
    {
        path: '/companies/:companieid',
        children: [
            {
                path: '',
                name: 'companies',
                component: () => import('../views/Companies/HomeView.vue')
            },
            {
                path: 'edit',
                name: 'companies-edit',
                component: () => import('../views/Companies/EditView.vue')
            },
            {
                path: 'credits',
                name: 'companies-credits',
                component: () => import('../views/Companies/CreditsView.vue')
            },
            {
                path: 'pricing',
                name: 'companies-pricing',
                component: () => import('../views/Companies/PricingView.vue')
            },
            {
                path: 'seo',
                children: [
                    {
                        path: 'reports',
                        children: [
                            {
                                path: '',
                                name: 'companies-seo-reports',
                                component: () => import('../views/Companies/SEO/Reports/HomeView.vue')
                            },
                            {
                                path: ':id',
                                name: 'companies-seo-reports-show',
                                component: () => import('../views/Companies/SEO/Reports/ShowView.vue')
                            },
                            {
                                path: ':id/edit',
                                name: 'companies-seo-reports-edit',
                                component: () => import('../views/Companies/SEO/Reports/EditView.vue'),
                                meta: { permission: 'seo.report.edit' }
                            }
                        ],
                    },
                    {
                        path: 'tools',
                        children: [
                            {
                                path: '',
                                name: 'companies-seo-tools',
                                component: () => import('../views/Companies/SEO/Tools/HomeView.vue'),
                                meta: { permission: 'seo.tools.create' }
                            },
                            {
                                path: ':slug',
                                name: 'companies-seo-tools-show',
                                component: () => import('../views/Companies/SEO/Tools/ShowView.vue'),
                                meta: { permission: 'seo.tools.create' }
                            },
                        ],
                    },
                ]
            },
            {
                path: 'wpp',
                children: [
                    {
                        path: 'devices',
                        children: [
                            {
                                path: '',
                                name: 'companies-wpp-devices',
                                component: () => import('../views/Companies/WPP/Devices/HomeView.vue')
                            },
                            {
                                path: ':id',
                                name: 'companies-wpp-devices-show',
                                component: () => import('../views/Companies/WPP/Devices/ShowView.vue'),
                                meta: { permission: 'wpp.device.edit' }
                            },
                            {
                                path: ':id/edit',
                                name: 'companies-wpp-devices-edit',
                                component: () => import('../views/Companies/WPP/Devices/EditView.vue'),
                                meta: { permission: 'wpp.device.edit' }
                            }
                        ]
                    },
                    {
                        path: 'sendmsg',
                        name: 'companies-wpp-sendmsg',
                        component: () => import('../views/Companies/WPP/Messages/SendView.vue'),
                        meta: { permission: 'wpp.message.send' }
                    },
                    {
                        path: 'outbox',
                        children: [
                            {
                                path: '',
                                name: 'companies-wpp-outbox',
                                component: () => import('../views/Companies/WPP/OutBox/HomeView.vue'),
                                meta: { permission: 'wpp.outbox.show' }
                            }
                        ]
                    },
                    {
                        path: 'autoreply',
                        children: [
                            {
                                path: '',
                                name: 'companies-wpp-autoreply',
                                component: () => import('../views/Companies/WPP/AutoReply/HomeView.vue')
                            },
                            {
                                path: 'create',
                                name: 'companies-wpp-autoreply-create',
                                component: () => import('../views/Companies/WPP/AutoReply/CreateView.vue'),
                                meta: { permission: 'wpp.autoreply.create' }
                            },
                            {
                                path: ':id/edit',
                                name: 'companies-wpp-autoreply-edit',
                                component: () => import('../views/Companies/WPP/AutoReply/EditView.vue'),
                                meta: { permission: 'wpp.autoreply.edit' }
                            }
                        ]
                    },
                    {
                        path: 'recurring',
                        children: [
                            {
                                path: '',
                                name: 'companies-wpp-recurring',
                                component: () => import('../views/Companies/WPP/Recurring/HomeView.vue')
                            },
                            {
                                path: 'create',
                                name: 'companies-wpp-recurring-create',
                                component: () => import('../views/Companies/WPP/Recurring/CreateView.vue'),
                                meta: { permission: 'wpp.recurring.create' }
                            },
                            {
                                path: ':id/edit',
                                name: 'companies-wpp-recurring-edit',
                                component: () => import('../views/Companies/WPP/Recurring/EditView.vue'),
                                meta: { permission: 'wpp.recurring.edit' }
                            }
                        ]
                    },
                    {
                        path: 'phonebook',
                        children: [
                            {
                                path: '',
                                name: 'companies-wpp-phonebook',
                                component: () => import('../views/Companies/WPP/PhoneBook/HomeView.vue')
                            },
                            {
                                path: 'create',
                                name: 'companies-wpp-phonebook-create',
                                component: () => import('../views/Companies/WPP/PhoneBook/CreateView.vue'),
                                meta: { permission: 'wpp.phonebook.create' }
                            },
                            {
                                path: ':id/edit',
                                name: 'companies-wpp-phonebook-edit',
                                component: () => import('../views/Companies/WPP/PhoneBook/EditView.vue'),
                                meta: { permission: 'wpp.phonebook.edit' }
                            }
                        ]
                    }
                ]
            },
            {
                path: 'ia',
                children: [
                    {
                        path: '',
                        name: 'companies-ia',
                        component: () => import('../views/Companies/IA/HomeView.vue')
                    },
                    {
                        path: 'app/:app',
                        name: 'companies-ia-app',
                        component: () => import('../views/Companies/IA/AppView.vue')
                    },
                    {
                        path: 'chats',
                        name: 'companies-ia-chats',
                        component: () => import('../views/Companies/IA/ChatsView.vue')
                    },
                    {
                        path: 'jogg',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-jogg',
                                component: () => import('../views/Companies/IA/Jogg/HomeView.vue')
                            },
                            // {
                            //     path: 'new/:type',
                            //     name: 'companies-ia-jogg-new',
                            //     component: () => import('../views/Companies/IA/Jogg/NewView.vue'),
                            //     meta: { permission: 'ia.video.create' }
                            // },
                            {
                                path: 'new/url',
                                name: 'companies-ia-jogg-new-url',
                                component: () => import('../views/Companies/IA/Jogg/NewVideoUrlView.vue'),
                                meta: { permission: 'ia.video.create' }
                            },
                            {
                                path: 'new/avatar',
                                name: 'companies-ia-jogg-new-avatar',
                                component: () => import('../views/Companies/IA/Jogg/NewVideoAvatarView.vue'),
                                meta: { permission: 'ia.video.create' }
                            }
                        ],
                    },
                    {
                        path: 'chat',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-chat',
                                component: () => import('../views/Companies/IA/Chat/HomeView.vue'),
                                meta: { permission: 'ia.chat.create' }
                            },
                            {
                                path: ':id',
                                name: 'companies-ia-chat-show',
                                component: () => import('../views/Companies/IA/Chat/ShowView.vue'),
                                meta: { permission: 'ia.chat.create' }
                            }
                        ],
                    },
                    {
                        path: 'coder',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-coder',
                                component: () => import('../views/Companies/IA/Coder/HomeView.vue'),
                                meta: { permission: 'ia.coder.create' }
                            },
                            {
                                path: ':id',
                                name: 'companies-ia-coder-show',
                                component: () => import('../views/Companies/IA/Coder/ShowView.vue')
                            }
                        ],
                    },
                    {
                        path: 'moderation',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-moderation',
                                component: () => import('../views/Companies/IA/Moderation/HomeView.vue'),
                                meta: { permission: 'ia.moderation.create' }
                            },
                            {
                                path: ':id',
                                name: 'companies-ia-moderation-show',
                                component: () => import('../views/Companies/IA/Moderation/ShowView.vue')
                            }
                        ],
                    },
                    {
                        path: 'voiceover',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-voiceover',
                                component: () => import('../views/Companies/IA/VoiceOver/HomeView.vue'),
                                meta: { permission: 'ia.voiceover.create' }
                            },
                            {
                                path: ':id',
                                name: 'companies-ia-voiceover-show',
                                component: () => import('../views/Companies/IA/VoiceOver/ShowView.vue')
                            },
                            {
                                path: 'voices',
                                name: 'companies-ia-voiceover-voices',
                                component: () => import('../views/Companies/IA/VoiceOver/VoicesView.vue'),
                                meta: { permission: 'ia.voiceover.create' }
                            }
                        ],
                    },
                    {
                        path: 'transcriber',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-transcriber',
                                component: () => import('../views/Companies/IA/Transcriber/HomeView.vue'),
                                meta: { permission: 'ia.transcriber.create' }
                            },
                            {
                                path: ':id',
                                name: 'companies-ia-transcriber-show',
                                component: () => import('../views/Companies/IA/Transcriber/ShowView.vue')
                            }
                        ],
                    },
                    {
                        path: 'imagine',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-imagine',
                                component: () => import('../views/Companies/IA/Imagine/HomeView.vue'),
                                meta: { permission: 'ia.image.create' }
                            },
                            {
                                path: ':id',
                                name: 'companies-ia-imagine-show',
                                component: () => import('../views/Companies/IA/Imagine/ShowView.vue')
                            }
                        ],
                    },
                    {
                        path: 'suno',
                        children: [
                            {
                                path: '',
                                name: 'companies-ia-suno',
                                component: () => import('../views/Companies/IA/Suno/HomeView.vue'),
                                meta: { permission: 'ia.music.create' }
                            }
                        ],
                    }
                ]
            },
            {
                path: 'team',
                children: [
                    {
                        path: '',
                        name: 'companies-team',
                        component: () => import('../views/Companies/Team/HomeView.vue')
                    },
                    {
                        path: 'create',
                        name: 'companies-team-create',
                        component: () => import('../views/Companies/Team/CreateView.vue'),
                        meta: { permission: 'team.create' }
                    },
                    {
                        path: ':id/edit',
                        name: 'companies-team-edit',
                        component: () => import('../views/Companies/Team/EditView.vue'),
                        meta: { permission: 'team.edit' }
                    }
                ]
            },
            {
                path: 'audits',
                name: 'companies-audits',
                component: () => import('../views/Companies/AuditsView.vue'),
                meta: { permission: 'audit.show' }
            },
            {
                path: 'status',
                name: 'companies-status',
                component: () => import('../views/Companies/StatusView.vue')
            },
            {
                path: 'integrations',
                name: 'companies-integrations',
                component: () => import('../views/Companies/IntegrationsView.vue')
            },
            {
                path: 'app',
                name: 'companies-app',
                component: () => import('../views/Companies/AppView.vue')
            },
            {
                path: 'services/:servicename',
                name: 'companies-services',
                component: () => import('../views/Companies/ServicesView.vue')
            }
        ]
    },
    {
        path: '/admin',
        children: [
            {
                path: '',
                name: 'admin',
                component: () => import('../views/Admin/HomeView.vue')
            },
            {
                path: 'companies',
                children: [
                    {
                        path: '',
                        name: 'admin-companies',
                        component: () => import('../views/Admin/Companies/HomeView.vue')
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-companies-edit',
                        component: () => import('../views/Admin/Companies/EditView.vue')
                    }
                ]
            },
            {
                path: 'users',
                children: [
                    {
                        path: '',
                        name: 'admin-users',
                        component: () => import('../views/Admin/Users/HomeView.vue')
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-users-edit',
                        component: () => import('../views/Admin/Users/EditView.vue')
                    }
                ]
            },
            {
                path: 'products',
                children: [
                    {
                        path: '',
                        name: 'admin-products',
                        component: () => import('../views/Admin/Products/HomeView.vue')
                    },
                    {
                        path: 'create',
                        name: 'admin-products-create',
                        component: () => import('../views/Admin/Products/CreateView.vue')
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-products-edit',
                        component: () => import('../views/Admin/Products/EditView.vue')
                    }
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: '',
                        name: 'admin-settings',
                        component: () => import('../views/Admin/Settings/HomeView.vue')
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-settings-edit',
                        component: () => import('../views/Admin/Settings/EditView.vue')
                    }
                ]
            },
            {
                path: 'assistents',
                children: [
                    {
                        path: '',
                        name: 'admin-assistents',
                        component: () => import('../views/Admin/Assistents/HomeView.vue')
                    },
                    {
                        path: 'create',
                        name: 'admin-assistents-create',
                        component: () => import('../views/Admin/Assistents/CreateView.vue')
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-assistents-edit',
                        component: () => import('../views/Admin/Assistents/EditView.vue')
                    }
                ]
            },
            {
                path: 'orders',
                children: [
                    {
                        path: '',
                        name: 'admin-orders',
                        component: () => import('../views/Admin/Orders/HomeView.vue')
                    }
                ]
            },
            {
                path: 'forms',
                children: [
                    {
                        path: '',
                        name: 'admin-forms',
                        component: () => import('../views/Admin/Forms/HomeView.vue')
                    }
                ]
            },
            {
                path: 'customavatars',
                children: [
                    {
                        path: '',
                        name: 'admin-customavatars',
                        component: () => import('../views/Admin/CustomAvatars/HomeView.vue')
                    },
                    {
                        path: 'create',
                        name: 'admin-customavatars-create',
                        component: () => import('../views/Admin/CustomAvatars/CreateView.vue')
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-customavatars-edit',
                        component: () => import('../views/Admin/CustomAvatars/EditView.vue')
                    }
                ]
            },
            {
                path: 'customvoices',
                children: [
                    {
                        path: '',
                        name: 'admin-customvoices',
                        component: () => import('../views/Admin/CustomVoices/HomeView.vue')
                    },
                    {
                        path: 'create',
                        name: 'admin-customvoices-create',
                        component: () => import('../views/Admin/CustomVoices/CreateView.vue')
                    },
                    {
                        path: 'edit/:id',
                        name: 'admin-customvoices-edit',
                        component: () => import('../views/Admin/CustomVoices/EditView.vue')
                    }
                ]
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ['login', 'register', 'confirm', 'forget'];
    const authRequired = !to.matched.some(record => publicPages.includes(record.name));
    const loggedIn = localStorage.getItem('user');
    const user = loggedIn ? JSON.parse(loggedIn) : null;
    const companie = store.state.companieData || null;
    const permissions = store.state.companieData?.permissions || [];
  
    if (authRequired && !loggedIn) {
        next('/login');
    } else if(companie && to.meta.permission) {
        if (user.id === companie.userId || Utils.isAdminOrModerator(user)) {
            next();
        } else {
            const requiredPermission = to.meta.permission;
            const hasPermission = permissions.some(company => 
                company.categories.some(category => 
                    category.values.some(value => value.model === requiredPermission && value.value === true)
                )
            );
    
            if (!hasPermission) {
                next('/access-denied');
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

export default router
