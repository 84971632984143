<template>
    <nav class="px-5 py-6 w-24 flex flex-col h-full bg-neutral-900 border-r border-neutral-600">
        <ul class="space-y-2 text-center text-xl text-white">
            <li>
                <router-link 
                    to="/" 
                    :class="{
                        'border-b-2 border-purple-800 bg-purple-700': $route.path === '/',
                        'bg-neutral-700': $route.path !== '/'
                    }"
                    class="flex-center w-14 h-14 rounded-xl"
                >
                    <font-awesome-icon :icon="['fas', 'house']" />
                </router-link>
            </li>
            <li v-if="this.$utils.isAdminOrModerator(this.currentUser)">
                <router-link 
                    to="/admin" 
                    :class="{
                        'border-b-2 border-blue-800 bg-blue-700': $route.path === '/admin',
                        'bg-neutral-700': $route.path !== '/admin'
                    }"
                    class="flex-center w-14 h-14 rounded-xl"
                >
                    <font-awesome-icon :icon="['fas', 'user-tie']" />
                </router-link>
            </li>
            <!-- <li>
                <router-link 
                    to="/auth" 
                    :class="{
                        'border-b-2 border-purple-800 bg-purple-700': $route.path === '/auth',
                        'bg-neutral-700': $route.path !== '/auth'
                    }"
                    class="flex-center w-14 h-14 rounded-xl"
                >
                    <font-awesome-icon :icon="['fas', 'fingerprint']" />
                </router-link>
            </li> -->
            <li>
                <router-link 
                    to="/config" 
                    :class="{
                        'border-b-2 border-purple-800 bg-purple-700': $route.path === '/config',
                        'bg-neutral-700': $route.path !== '/config'
                    }"
                    class="flex-center w-14 h-14 rounded-xl"
                >
                    <font-awesome-icon :icon="['fas', 'gear']" />
                </router-link>
            </li>
        </ul>
        <button class="mt-auto text-white" @click="logOut"><font-awesome-icon :icon="['fas', 'right-from-bracket']" /></button>
    </nav>
</template>

<script>
export default {
    name: "MenuComponent",
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        logOut() {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        }
    }
}
</script>