<template>
    <div class="relative z-10" @click="openWhatsApp">
        <div class="cursor-pointer fixed bottom-5 right-5 rounded-full shadow-lg bg-purple-600 text-gray-100 hover:bg-purple-500 w-12 h-12 text-center text-2xl p-2">
            <font-awesome-icon :icon="['fab', 'whatsapp']" />
        </div>
        <!-- <div class="absolute right-3 bottom-5 p-2 text-xs text-white dark:text-black bg-gray-800 dark:bg-gray-100 rounded">
            Suporte
        </div> -->
    </div>
</template>

<script>
export default {
    name: "SupportComponent",
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        generateWhatsAppLink(message) {
            const phoneNumber = "+554384622185";
            const encodedMessage = encodeURIComponent(message);
            return `https://wa.me/${phoneNumber.replace('+', '')}?text=${encodedMessage}`;
        },
        openWhatsApp() {
            let message = `Olá, estou precisando de ajuda no painel.`

            if (this.currentUser) {
                message = `Olá, meu nome é *${this.currentUser.name}* e estou precisando de ajuda no painel.`
            }
            
            const link = this.generateWhatsAppLink(message);
            window.open(link, '_blank');
        },
    }
}
</script>